import { Box, Flex, List, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import classes from './Courses.module.css';
import PageContainer from '../../../components/PageContainer';
import { ReactComponent as Check } from '../../../assets/icons/Check.svg';
import { getNearestStudyFlows } from '../../../api/studyFlows';
import { bodyTextColorHex } from '../../../theme';
import Card from '../../../components/Card';
import { Course, createPayments } from '../../../api/courses';

function filterNotEmptyArray<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

const Courses = () => {
  const { data: nearestStudyFlows } = useQuery({
    queryKey: ['nearestStudyFlows'],
    queryFn: getNearestStudyFlows,
  });

  const nearestCourses = nearestStudyFlows
    ?.map((nearestStudyFlow) => nearestStudyFlow.course)
    .filter(filterNotEmptyArray);

  console.log(nearestCourses);


  const purchaseCourse = async (course: Course) => {
    try {
      const currency = process.env.REACT_APP_CURRENCY || 'UAH'; // Provide a default value
      const values = {
        amount: course.price,
        currency,
        orderTimeout: 86400,
        productName: [course.name],
        productPrice: [course.price],
        productCount: [1],
      };
      const response: any = await createPayments(values);
      if (response) {
        console.log("Payment response:", response);
        window.location.href = response.invoiceUrl;
      }
    } catch (error) {
      console.error("Payment error:", error);
    }
  }

  return (
    <PageContainer title="Курси">
      <div className={classes.pageContent}>
        <Box className={classes.pageTitleContainer}>
          <Title order={4} fz={20} fw="normal">
            Наші курси, які ви можете придбати
          </Title>
        </Box>
        <Box
          className={!nearestCourses?.length ? classes.emptyList : classes.list}
        >
          {nearestCourses?.length ? (
            nearestCourses?.map((course) => (
              <Card
                title={course?.name}
                className={classes.courseCard}
                subtitle="Курс"
                image={course.image}
                button={{
                  label: 'Придбати курс',
                  onClick: () => purchaseCourse(course),
                }}
              >
                <Flex h="100%" direction="column" justify="space-between">
                  <List spacing="xs" size="md" py="20px" icon={<Check />}>
                    {course?.features?.map((feature) => (
                      <List.Item>{feature}</List.Item>
                    ))}
                  </List>
                  <Title order={3} fw="bolder" mb="32px" fz="32px" ta="center">
                    {course?.price}$
                  </Title>
                </Flex>
              </Card>
            ))
          ) : (
            <Title order={4} fz={24} fw="normal" c={bodyTextColorHex}>
              У вас немає курсів які ви можете придбати
            </Title>
          )}
        </Box>
      </div>
    </PageContainer>
  );
};

export default Courses;
